

<template>
  <section id="grading-assessment">
    <Breadcrumb
      :links="[
        {
          label: 'Grading',
          link: true,
          route: (prevRoute && prevRoute.name === `${user.role.toLowerCase().replace(/^./, c => c.toUpperCase())} Grading`) ? { name: `${user.role.toLowerCase().replace(/^./, c => c.toUpperCase())} Grading`, query: { ...prevRoute.query} } : { name: `${user.role.toLowerCase().replace(/^./, c => c.toUpperCase())} Grading`, query: { search: '', page: 1, paginate: 10 } },
        },
        {
          label: `${grading_title}`,
          link: false,
          route: null,
        },
      ]"
    />
    <SearchBar 
      :placeholder="'Search assessment or evaluation'" 
      :value.sync="search" 
      @clear="clearSearch"
      @search="onSearch"
      class="mb-5"/>

    <v-data-table
      :headers="grading_evaluation_tbl"
      :items="grading"
      class="text--center custom-border poppins f14 mt-3"
      :loading="loading"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
          'items-per-page-options': itemsPerPageOptions
      }"
      :page="page"
      :server-items-length="totalCount"
      @pagination="(e) => {
          page = e.page
          paginate = String(e.itemsPerPage),
          updateQuery()
      }">
      <template v-slot:item.type_of_assessment="{ item }">
        <span class="text-capitalize">
          {{ item?.type_of_assessment ? item.type_of_assessment.replace('_', ' ') : '' }}
          {{ item?.student_course_evaluations ? 'Course Evaluation' : '' }}
          {{ item?.student_module_evaluations ? 'Module Evaluation' : '' }}
        </span>
      </template>
      <template v-slot:item.no_of_submitters="{ item }">
        <span>
          {{ item?.assessment_scores ? item.assessment_scores.length : '' }}
          {{ item?.student_course_evaluations ? item.student_course_evaluations.length : '' }}
          {{ item?.student_module_evaluations ? item.student_module_evaluations.length : '' }}
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          text
          small
          v-if="item?.type_of_assessment"
          class="primary--text text-capitalize"
          :to="{ name: `${user.role.toLowerCase().replace(/^./, c => c.toUpperCase())} Grading Assessment`, params: { course_id: $route.params.id, id: item.id, course_name: item.name }, query: { search: '', page: 1, paginate: 10, search: '' } }"
        >
          <v-icon left>mdi-eye-outline</v-icon>
          View
        </v-btn>
        <v-btn
          text
          small
          v-if="item?.student_course_evaluations"
          class="primary--text text-capitalize"
          :to="{ name: `${user.role.toLowerCase().replace(/^./, c => c.toUpperCase())} Survey`, params: { id: $route.params.id, survey_id: item.id, type: 'course_evaluation' }, query: { search: '', page: 1, paginate: 10 } }"
        >
          <v-icon left>mdi-eye-outline</v-icon>
          View
        </v-btn>
        <v-btn
          text
          small
          v-if="item?.student_module_evaluations"
          class="primary--text text-capitalize"
          :to="{ name: `${user.role.toLowerCase().replace(/^./, c => c.toUpperCase())} Survey`, params: { id: $route.params.id, survey_id: item.id, type: 'module_evaluation' }, query: { search: '', page: 1, paginate: 10 } }"
        >
          <v-icon left>mdi-eye-outline</v-icon>
          View
        </v-btn>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import searchAndPaginateMixin from '@/mixins/searchAndPaginateMixin';
import { grading_assessment_tbl, grading_evaluation_tbl } from '@/constants/tblheaders/grading';
import Breadcrumb from "../../../layouts/teacher/InstructorBreadcrumbs.vue";

export default {
  mixins: [searchAndPaginateMixin],
  components: { Breadcrumb },
  methods: {
    ...mapActions(
      "instructor", 
        { 
            getInstructorGradingAssessmentsEvaluations: "getGradingAssessmentsEvaluations", 
        }
    ),

    ...mapActions(
      "admin", 
        { 
            getAdminGradingAssessmentsEvaluations: "getGradingAssessmentsEvaluations",
        }
    ),
    
    ...mapMutations('instructor', {
      getInstructorGradingMutation : 'gradingMutation'
    }),

    ...mapMutations('admin', {
      getAdminGradingMutation : 'gradingMutation'
    }),

    getData(){
      if(!this.loading) {
        if(this.user.role === 'INSTRUCTOR') {
          this.loading = true
          this.getInstructorGradingAssessmentsEvaluations({ id: this.$route.params.id, paginate: Number(this.paginate), page: this.page, search: this.search}).then(res => {
            this.grading_title = res.course_title
            this.pageCount = res.paginated_result.last_page
            this.paginate = res.paginated_result.per_page.toString()
            this.page = res.paginated_result.current_page
            this.totalCount = res.paginated_result.total
            this.loading = false
          }).catch(()=>{
            this.loading = false
          })
        }
        if(this.user.role === 'ADMIN') {
          this.loading = true
          this.getAdminGradingAssessmentsEvaluations({ id: this.$route.params.id, paginate: Number(this.paginate), page: this.page, search: this.search}).then(res => {
            this.grading_title = res.course_title
            this.pageCount = res.paginated_result.last_page
            this.paginate = res.paginated_result.per_page.toString()
            this.page = res.paginated_result.current_page
            this.totalCount = res.paginated_result.total
            this.loading = false
          }).catch(()=>{
            this.loading = false
          })
        }
      }
    },

    // viewAssessment(e) {
    //   this.$router.push({ name: 'Instructor Grading Assessment', params: { id: e.id }, query: { page: 1 } })
    // },

    // viewEvaluation(e) {
    //   if(e.type === 'COURSE EVALUATION') {
    //     this.$router.push({ name: 'Instructor Survey', params: { id: this.$route.params.id, survey_id: e.id, type: 'course_evaluation' }, query: { search: '', page: 1, paginate: 10 } })
    //   } else {
    //     this.$router.push({ name: 'Instructor Survey', params: { id: this.$route.params.id, survey_id: e.id, type: 'module_evaluation' }, query: { search: '', page: 1, paginate: 10 } })
    //   }
    // },
  },
  mounted(){
    this.getData()
  },
  created(){
    this.getInstructorGradingMutation([])
    this.getAdminGradingMutation([])
  },
  computed:{
    ...mapState({
      user: (state) => state.user
    }),

    grading(){
      if (!this.user) return [];

      const gradingMap = {
          INSTRUCTOR: this.instructorGrading,
          ADMIN: this.adminGrading,
      };

      return gradingMap[this.user.role] || [];
    },
    
    ...mapState('admin', {
        adminGrading: (state) => state.grading,
        adminAssessmentList(state) {
          const _assessmentList = [
            {
              title: 'Graded Assessment',
              type_of_assessment: 'graded_assessment',
              assessments: []
            },
            {
              title: 'Ungraded Assessment',
              type_of_assessment: 'ungraded_assessment',
              assessments: []
            },
            {
              title: 'Survey',
              type_of_assessment: 'survey',
              assessments: []
            },
          ]
          state.grading.assessments.forEach(item => {
            let index = _assessmentList.findIndex(e => {return e.type_of_assessment === item.type_of_assessment})
            if(index != -1){
              _assessmentList[index].assessments.push(
                { 
                  id: item.id,
                  title: item.title?item.title: 'Untitled', 
                  checking: item.assessment_scores.length,
                  due_date: item.due_date ? !!JSON.parse(item.due_date).end ? JSON.parse(item.due_date).end : 'Not Assigned' : 'Not Assigned'
                  //due_date: item.due_date.end ? 'Not assigned': JSON.parse(item.due_date).end
                }
              )
            }
          })

          state.grading.course_evaluations.forEach(item  => {
            _assessmentList[2].assessments.push(
                { 
                  id: item.id,
                  title: item.title, 
                  type: 'COURSE EVALUATION',
                  no_of_submitters: item.student_course_evaluations.length
                  //due_date: item.due_date.end ? 'Not assigned': JSON.parse(item.due_date).end
                }
              )
          })

          state.grading.module_evaluations.forEach(item  => {
            _assessmentList[2].assessments.push(
                { 
                  id: item.id,
                  title: item.title, 
                  type: 'MODULE EVALUATION',
                  no_of_submitters: item.student_module_evaluations.length
                  //due_date: item.due_date.end ? 'Not assigned': JSON.parse(item.due_date).end
                }
              )
          })
          
          return _assessmentList
        },
    }),
    
    ...mapState('instructor', {
        instructorGrading: (state) => state.grading,
        instructorAssessmentList(state) {
          const _assessmentList = [
            {
              title: 'Graded Assessment',
              type_of_assessment: 'graded_assessment',
              assessments: []
            },
            {
              title: 'Ungraded Assessment',
              type_of_assessment: 'ungraded_assessment',
              assessments: []
            },
            {
              title: 'Survey',
              type_of_assessment: 'survey',
              assessments: []
            },
          ]
          state.grading.assessments.forEach(item => {
            let index = _assessmentList.findIndex(e => {return e.type_of_assessment === item.type_of_assessment})
            if(index != -1){
              _assessmentList[index].assessments.push(
                { 
                  id: item.id,
                  title: item.title?item.title: 'Untitled', 
                  checking: item.assessment_scores.length,
                  due_date: item.due_date ? !!JSON.parse(item.due_date).end ? JSON.parse(item.due_date).end : 'Not Assigned' : 'Not Assigned'
                  //due_date: item.due_date.end ? 'Not assigned': JSON.parse(item.due_date).end
                }
              )
            }
          })

          state.grading.course_evaluations.forEach(item  => {
            _assessmentList[2].assessments.push(
                { 
                  id: item.id,
                  title: item.title, 
                  type: 'COURSE EVALUATION',
                  no_of_submitters: item.student_course_evaluations.length
                  //due_date: item.due_date.end ? 'Not assigned': JSON.parse(item.due_date).end
                }
              )
          })

          state.grading.module_evaluations.forEach(item  => {
            _assessmentList[2].assessments.push(
                { 
                  id: item.id,
                  title: item.title, 
                  type: 'MODULE EVALUATION',
                  no_of_submitters: item.student_module_evaluations.length
                  //due_date: item.due_date.end ? 'Not assigned': JSON.parse(item.due_date).end
                }
              )
          })
          
          return _assessmentList
        },
    }),

    itemsPerPage(){
      return Number(this.paginate)
    },

    paginationPaginate(){
        return String(this.paginate)
    }
  },
  data: () => ({
    grading_assessment_tbl,
    grading_evaluation_tbl,
    search: '',
    grading_title: '',
    loading: false,
    pageCount: 1,
    paginate: '10',
    page: 1,
    totalCount: 0,
    prevRoute: null,
    itemsPerPageOptions: [5, 10, 20, 30, 40, 50],
  }),
  
  beforeRouteEnter(to, from, next) {
    next(vm => {
        vm.prevRoute = from          
    })
  },
};
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>